import React from 'react'
import Layout from '../components/layout'
import 'bulma/css/bulma.min.css'

const UnirseComunidadFrances = () => {
  return (
    <Layout>
      <div className="wrapper">
        <section className="hero is-medium community-hero">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title community-title">
                Únete a Nuestra Comunidad de Aprendizaje de Francés
              </h1>
              <p className="subtitle community-subtitle">
                ¡Mejora tu francés conectándote con otros estudiantes en nuestro
                servidor de Discord!
              </p>
              <a
                className="button is-dark mt-5"
                href="https://discord.gg/BqJxSvYepH"
              >
                Únete a la Comunidad Ahora
              </a>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns is-vcentered is-multiline">
              <div className="column is-half">
                <h2 className="title community-title-secondary">
                  ¿Qué Obtendrás?
                </h2>
                <p className="content community-content">
                  ¡Descubre una forma divertida e interactiva de aprender
                  francés! Al unirte a nuestra comunidad, tendrás:
                </p>
                <ul className="community-list">
                  <li>
                    🔹 <strong>Práctica diaria</strong> de conversación en
                    francés
                  </li>
                  <li>
                    🔹 <strong>Charlas amigables</strong> sobre la cultura,
                    comida y arte francés
                  </li>
                  <li>
                    🔹 <strong>Eventos interactivos</strong> y desafíos grupales
                  </li>
                  <li>
                    🔹 <strong>Acceso a recursos</strong>, consejos y guías
                  </li>
                </ul>
              </div>
              <div className="column is-half">
                <figure className="image is-4by3 community-image">
                  <img
                    src="/assets/images/CommunityImage.jpg"
                    alt="Comunidad de Francés"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="section community-background-light">
          <div className="container has-text-centered">
            <h2 className="title community-title-secondary">
              ¡Conoce a Estudiantes de Francés de Todo el Mundo!
            </h2>
            <p className="content community-content">
              Ya seas un principiante o un estudiante avanzado, nuestra
              comunidad te ayudará a practicar y mejorar tu francés en un
              ambiente amigable.
            </p>
            <a className="button mt-4" href="https://discord.gg/BqJxSvYepH">
              ¡Únete a Nuestro Discord Ahora!
            </a>
          </div>
        </section>
        <section className="section">
          <div className="container community-message-container">
            <div className="columns is-multiline is-variable is-8">
              <div className="column is-one-third community-message-column">
                <article className="message community-message">
                  <div className="message-header community-message-header">
                    <p>Desafíos Diarios</p>
                  </div>
                  <div className="message-body community-message-body">
                    ¡Recibe un nuevo desafío en francés cada día para mantener
                    tus habilidades afiladas!
                  </div>
                </article>
              </div>

              <div className="column is-one-third community-message-column">
                <article className="message community-message">
                  <div className="message-header community-message-header">
                    <p>Guía de Expertos</p>
                  </div>
                  <div className="message-body community-message-body">
                    Nuestra comunidad incluye hablantes nativos y estudiantes
                    experimentados listos para ayudar.
                  </div>
                </article>
              </div>

              <div className="column is-one-third community-message-column">
                <article className="message community-message">
                  <div className="message-header community-message-header">
                    <p>Ambiente Amigable</p>
                  </div>
                  <div className="message-body community-message-body">
                    Haz amigos y aprende junto a un grupo de apoyo de otros
                    estudiantes.
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>

        <section className="section community-cta-section">
          <div className="container has-text-centered">
            <h2 className="title community-title-secondary">
              ¿Listo para Hablar Francés con Confianza?
            </h2>
            <p className="content community-content is-size-5">
              No esperes más. Comienza tu viaje hacia la fluidez en francés hoy
              mismo.
            </p>
            <a
              id="unirse"
              className="button is-dark mt-4"
              href="https://discord.gg/BqJxSvYepH"
            >
              ¡Únete a la Comunidad Ahora!
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default UnirseComunidadFrances
